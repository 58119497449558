import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { playerStoreInstance, PlayerStoreContext } from './stores/PlayerStore';
import Dashboard from './Dashboard';
import Login from './Login';
import Header from './Header';
import LandingPage from './LandingPage';
import { CookieProvider } from './CookieContext';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getLeagueUserInfo } from './api';
import { QueryClient, QueryClientProvider } from 'react-query';
import apiService from './api';
import { FaSpinner } from 'react-icons/fa';
const queryClient = new QueryClient();

const App = () => {
  const [hasToken, setHasToken] = useState(false);
  const [user, setUser] = useState(null);
  const [leagues, setLeagues] = useState([]);
  const [selectedLeagueId, setSelectedLeagueId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const initializeApp = async () => {
      const token = localStorage.getItem('token');
      if (token && !playerStoreInstance.isTokenExpired()) {
        setHasToken(true);
        const userData = localStorage.getItem('user');
        setUser(JSON.parse(userData));

        try {
          const leagueInfo = await apiService.getLeagueUserInfo(token);
          if (leagueInfo && leagueInfo.leagues && leagueInfo.leagues.length > 0) {
            setLeagues(leagueInfo.leagues);
            const storedLeagueId = localStorage.getItem('selectedLeagueId');
            const firstLeagueId = leagueInfo.leagues[0].id;
            const leagueId = storedLeagueId || firstLeagueId;
            setSelectedLeagueId(leagueId);
            playerStoreInstance.setLeagueId(leagueId);
          } else {
            throw new Error('No leagues found');
          }
        } catch (error) {
          console.error('Error fetching leagues:', error);
          handleLogout();
        }
      }
      setIsLoading(false);
    };

    initializeApp();
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('selectedLeagueId');
    setUser(null);
    setHasToken(false);
    setSelectedLeagueId(null);
    setLeagues([]);
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <FaSpinner className="animate-spin text-4xl text-blue-500" />
      </div>
    );
  }

  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <div className="bg-gray-900 w-full text-white">
          <CookieProvider>
            <PlayerStoreContext.Provider value={playerStoreInstance}>
              <Routes>
                <Route path="/login" element={!hasToken ? <LandingPage /> : <Navigate to="/dashboard" />} />
                <Route
                  path="/dashboard"
                  element={
                    hasToken ? (
                      <>
                        <Header onLogout={handleLogout} user={user} />
                        {selectedLeagueId && (
                          <>
                     
                            <Dashboard leagueId={selectedLeagueId} />
                          </>
                        )}
                      </>
                    ) : (
                      <Navigate to="/login" />
                    )
                  }
                />
                <Route path="*" element={<Navigate to={hasToken ? "/dashboard" : "/login"} />} />
              </Routes>
            </PlayerStoreContext.Provider>
          </CookieProvider>
        </div>
        <ToastContainer />
      </Router>
    </QueryClientProvider>
  );
};

export default App;