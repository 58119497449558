import React, { useState, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import PlayerDetailModal from "./PlayerDetailModal";
import PlayerTimeline from "./PlayerTimeline";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
     faArrowUp, faArrowDown,faBinoculars, 
 faEquals,
     faChevronDown, faChevronUp,
     faBell, faBellSlash, // Add these icons
} from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';
import ClipLoader from "react-spinners/ClipLoader";
import PlayerSearch from './PlayerSearch';
import { usePlayerStore } from '../src/stores/PlayerStore';
import VoteButtons from './components/VoteButtons';
import { getLeagueQuickStats } from './api';
import { 
    requestForToken, 
    onMessageListener, 
    messaging, 
    scheduleNotification, 
    subscribeToPlayerNotifications, 
    unsubscribeFromPlayerNotifications 
} from './firebase';
import { toast } from 'react-toastify';  // Make sure to import this at the top of your file

const MiniTrendGraph = ({ data, width = 50, height = 20 }) => {
  if (!data || data.length < 2) return null;

  const values = data.map(d => d.m);
  const minValue = Math.min(...values);
  const maxValue = Math.max(...values);
  const xStep = width / (data.length - 1);

  const points = data.map((d, i) => {
    const x = i * xStep;
    const y = height - ((d.m - minValue) / (maxValue - minValue)) * height;
    return `${x},${y}`;
  }).join(' ');

  return (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
      <polyline
        fill="none"
        stroke="#4CAF50"
        strokeWidth="2"
        points={points}
      />
    </svg>
  );
};

const MarketOverview = observer(() => {
    const playerStore = usePlayerStore();

    const [dataModalOpen, setDataModalOpen] = useState(false);
    const [selectedPlayer, setSelectedPlayer] = useState(null);
    const [sortType, setSortType] = useState('expire');
    const [showAllPlayers, setShowAllPlayers] = useState(false);
    const [players, setPlayers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchModalOpen, setSearchModalOpen] = useState(false);
    const [showFitInfo, setShowFitInfo] = useState({});
    const [calculatedPlayerData, setCalculatedPlayerData] = useState({});
    const [selectedValue, setSelectedValue] = useState(null);
    const [renderKey, setRenderKey] = useState(0);
    const [showRecommendationDetails, setShowRecommendationDetails] = useState({});
    const [userId, setUserId] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [user, setUser] = useState(null);
    const [notificationEnabled, setNotificationEnabled] = useState(() => {
        const savedNotifications = localStorage.getItem('notificationEnabled');
        return savedNotifications ? JSON.parse(savedNotifications) : {};
    });
    const [notificationPermission, setNotificationPermission] = useState('default');
    const [showNotificationDialog, setShowNotificationDialog] = useState(false);
    const [pendingNotificationPlayerId, setPendingNotificationPlayerId] = useState(null);

    // Use playerStore.budget instead of local state
    const budget = playerStore.budget;

    Modal.setAppElement('#root');


    useEffect(() => {
        const fetchUserInfo = async () => {
            const leagueId = playerStore.leagueId;
            if (leagueId) {
                try {
                    const quickStats = await getLeagueQuickStats(leagueId);
                    console.log('quickStats:', quickStats);
                    setUserId(quickStats.cid);
                    playerStore.setUserInfo({
                        teamValue: quickStats.t,
                        budget: quickStats.nub,
                        id: quickStats.cid
                    });
                } catch (error) {
                    console.error('Error fetching user info:', error);
                }
            }
        };

        fetchUserInfo();
    }, [playerStore.leagueId]);
    
    useEffect(() => {
        console.log('userId changed:', userId);
    }, [userId]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            await playerStore.fetchLeagueInfo();
            await playerStore.fetchMarketPlayers();
            await playerStore.fetchRecentSales();
            setPlayers(playerStore.marketPlayers);
            setLoading(false);
            setRenderKey(prev => prev + 1);
        };
        fetchData();
    }, [playerStore]);

    const sortedAndFilteredPlayers = useMemo(() => {
        if (!players || !players.length) return [];
        
        let filteredPlayers = showAllPlayers ? players : players.filter(player => player && !player.username);
        
        return filteredPlayers.filter(player => player != null).sort((a, b) => {
            switch (sortType) {
                case 'expire':
                    return a.expire - b.expire;
                case 'marketValue':
                    return b.marketValue - a.marketValue;
                case 'averagePoints':
                    return b.averagePoints - a.averagePoints;
                case 'pointsPerEuro':
                    return (b.averagePoints / b.marketValue) - (a.averagePoints / a.marketValue);
                case 'valueChange':
                    return b.marketValueIncrease - a.marketValueIncrease;
                case 'valueForMoney':
                    return (b.totalPoints / b.buyPrice) - (a.totalPoints / a.buyPrice);
                default:
                    return a.expire - b.expire;
            }
        });
    }, [players, sortType, showAllPlayers]);

    useEffect(() => {
        const calculatePlayerData = async () => {
            setLoading(true);
            const calculatedData = {};
            for (const player of sortedAndFilteredPlayers) {
                calculatedData[player.id] = await player.calculateFitPercentage(playerStore);
                const estimatedSalePrice = await playerStore.estimateSalePrice(player.id);
                calculatedData[player.id].estimatedSalePrice = parseFloat(estimatedSalePrice).toFixed(2);
                
                // Fetch player market value history
                const marketValueHistory = await playerStore.fetchPlayerMarketValueHistory(player.id);
                calculatedData[player.id].marketValueHistory = marketValueHistory;
            }
            setCalculatedPlayerData(calculatedData);
            setLoading(false);
        };
        calculatePlayerData();
    }, [sortedAndFilteredPlayers, playerStore]);

    const getCountdown = (expireInSeconds) => {
        const totalSeconds = parseInt(expireInSeconds, 10);
        if (totalSeconds <= 0) return "Expired";

        const days = Math.floor(totalSeconds / (60 * 60 * 24));
        const hours = Math.floor((totalSeconds % (60 * 60 * 24)) / (60 * 60));
        const minutes = Math.floor((totalSeconds % (60 * 60)) / 60);
        const seconds = totalSeconds % 60;

        return `${days}d ${hours}h ${minutes}m ${seconds}s`;
    };

    const toggleFitInfo = (playerId) => {
        setShowFitInfo(prev => ({ ...prev, [playerId]: !prev[playerId] }));
    };

    const getFitColor = (percentage) => {
        if (percentage >= 70) return 'text-green-500';
        if (percentage >= 40) return 'text-yellow-500';
        return 'text-red-500';
    };

    // Function to format currency without cents
    const formatCurrency = (value) => {
        return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(value);
    };

    const formatLargeNumber = (num) => {
        const absNum = Math.abs(num);
        if (absNum >= 1000000) {
            return (num / 1000000).toFixed(2) + 'M';
        } else if (absNum >= 1000) {
            return (num / 1000).toFixed(0) + 'K';
        }
        return num.toFixed(0);
    };

    const calculateMarketValueTrend = (player) => {
        if (player.marketValues && player.marketValues.length >= 2) {
            const last7Values = player.marketValues.slice(-7);
            const oldestValue = last7Values[0].m;
            const newestValue = last7Values[last7Values.length - 1].m;
            return newestValue - oldestValue;
        }
        return 0; // Return 0 if there's not enough data
    };

    const getTrendArrows = (trendValue) => {
        const arrows = [];
        const absValue = Math.abs(trendValue);
        const isPositive = trendValue >= 0;
        const icon = isPositive ? faArrowUp : faArrowDown;
        const color = isPositive ? 'text-green-500' : 'text-red-500';

        for (let i = 0; i < 7; i++) {
            if (absValue > i * 100000) { // Adjust this threshold as needed
                arrows.push(<FontAwesomeIcon key={i} icon={icon} className={`${color} mx-0.5`} />);
            } else {
                arrows.push(<FontAwesomeIcon key={i} icon={faEquals} className="text-gray-400 mx-0.5" />);
            }
        }
        return arrows;
    };

    const canBuyPlayer = (player, playerData) => {
        if (!playerStore.leagueInfo) {
            console.warn('League info not available');
            return { canBuy: true, reason: "" }; // Default to allowing purchase if we don't have league info
        }

        const teamPlayersCount = playerStore.players.filter(p => p.teamId === player.teamId).length;
        const totalPlayersCount = playerStore.players.length;
        const financialPercentage = playerData.financialPercentage;

        if (teamPlayersCount >= playerStore.leagueInfo.mpst) {
            return { canBuy: false, reason: "Maximale Spieler pro Team erreicht" };
        }
        if (totalPlayersCount >= playerStore.leagueInfo.pl) {
            return { canBuy: false, reason: "Maximale Spieleranzahl erreicht" };
        }
        if (financialPercentage > 100) {
            return { canBuy: false, reason: "Nicht genug Budget" };
        }
        return { canBuy: true, reason: "" };
    };

    const toggleRecommendationDetails = (playerId) => {
        setShowRecommendationDetails(prev => ({
            ...prev,
            [playerId]: !prev[playerId]
        }));
    };

    useEffect(() => {
        const checkPermission = async () => {
            const permission = await Notification.requestPermission();
            setNotificationPermission(permission);
        };
        checkPermission();
    }, []);

    const schedulePlayerNotification = async (playerId) => {
      const player = sortedAndFilteredPlayers.find(p => p.id === playerId);
      if (!player) return;

      const now = Date.now();
      const expirationTime = now + (parseInt(player.expire, 10) * 1000); // Convert seconds to milliseconds and add to current time
      const notificationTime = expirationTime - (10 * 60 * 1000); // 10 minutes before expiration

      // Check if the notification time is in the future
      if (notificationTime <= now) {
        console.log(`Cannot schedule notification for ${player.firstName} ${player.lastName}: Auction ends in less than 10 minutes`);
        toast.warn('Auktion endet in weniger als 10 Minuten. Keine Benachrichtigung möglich.');
        return;
      }

      try {
        const result = await scheduleNotification({
          playerId: player.id,
          playerName: `${player.firstName} ${player.lastName}`,
          expirationTime: expirationTime,
        });
        console.log('Notification scheduled:', result);
      } catch (error) {
        console.error('Error scheduling notification:', error);
        toast.error('Fehler beim Planen der Benachrichtigung. Bitte versuche es erneut.');
      }
    };

    const requestNotificationPermission = async () => {
        const result = await Notification.requestPermission();
        setNotificationPermission(result);
        return result;
    };

    const handleNotificationRequest = async (playerId) => {
        if (notificationPermission === 'granted') {
            toggleNotification(playerId);
        } else if (notificationPermission === 'default') {
            setPendingNotificationPlayerId(playerId);
            setShowNotificationDialog(true);
        } else {
            toast.error('Benachrichtigungen sind in deinem Browser deaktiviert. Bitte aktiviere sie in den Einstellungen.');
        }
    };

    const handleNotificationDialogConfirm = async () => {
        setShowNotificationDialog(false);
        const permission = await requestNotificationPermission();
        if (permission === 'granted' && pendingNotificationPlayerId) {
            toggleNotification(pendingNotificationPlayerId);
        }
        setPendingNotificationPlayerId(null);
    };

    const toggleNotification = async (playerId) => {
        const player = sortedAndFilteredPlayers.find(p => p.id === playerId);
        if (!player) return;

        setNotificationEnabled(prev => {
            const newState = { ...prev, [playerId]: !prev[playerId] };
            localStorage.setItem('notificationEnabled', JSON.stringify(newState));
            return newState;
        });

        if (!notificationEnabled[playerId]) {
            if (notificationPermission === 'granted') {
                try {
                    const token = await requestForToken();
                    if (token) {
                        console.log('Attempting to subscribe with token:', token);
                        const result = await subscribeToPlayerNotifications({ playerId, token });
                        console.log('Subscription result:', result);
                        await schedulePlayerNotification(playerId);
                        
                        console.log(`Subscribed to notifications for player ${playerId}`);
                        toast.success('Benachrichtigung aktiviert. Du wirst 10 Minuten vor Auktionsende informiert.');
                    } else {
                        console.error('No token available');
                        toast.error('Fehler beim Aktivieren der Benachrichtigung. Bitte versuche es erneut.');
                        revertNotificationState(playerId);
                    }
                } catch (error) {
                    console.error('Error subscribing to notifications:', error);
                    console.error('Error details:', JSON.stringify(error));
                    toast.error('Fehler beim Aktivieren der Benachrichtigung. Bitte versuche es erneut.');
                    revertNotificationState(playerId);
                }
            } else {
                alert('Bitte aktiviere Benachrichtigungen in deinen Browsereinstellungen.');
                revertNotificationState(playerId);
            }
        } else {
            try {
                const token = await requestForToken();
                if (token) {
                    await unsubscribeFromPlayerNotifications({ playerId, token });
                    console.log(`Unsubscribed from notifications for player ${playerId}`);
                    toast.success('Benachrichtigung deaktiviert.');
                } else {
                    console.error('No token available for unsubscribing');
                    toast.error('Fehler beim Deaktivieren der Benachrichtigung. Bitte versuche es erneut.');
                    revertNotificationState(playerId);
                }
            } catch (error) {
                console.error('Error unsubscribing from notifications:', error);
                toast.error('Fehler beim Deaktivieren der Benachrichtigung. Bitte versuche es erneut.');
                revertNotificationState(playerId);
            }
        }
    };

    const revertNotificationState = (playerId) => {
        setNotificationEnabled(prev => {
            const newState = { ...prev, [playerId]: !prev[playerId] };
            localStorage.setItem('notificationEnabled', JSON.stringify(newState));
            return newState;
        });
    };

    useEffect(() => {
        const unsubscribe = onMessageListener().then((payload) => {
            console.log('Received foreground message ', payload);
            // Handle the received message, e.g., show a toast notification
            toast.info(`${payload.notification.title}: ${payload.notification.body}`);
        }).catch(err => console.log('failed: ', err));

        // Cleanup subscription on unmount
        return () => unsubscribe;
    }, []);

    useEffect(() => {
        localStorage.setItem('notificationEnabled', JSON.stringify(notificationEnabled));
    }, [notificationEnabled]);

    useEffect(() => {
        const setupNotifications = async () => {
            const permission = await Notification.requestPermission();
            setNotificationPermission(permission);
            if (permission === 'granted') {
                const token = await requestForToken();
                console.log('FCM Token:', token);
            }
        };
        setupNotifications();
    }, []);

    return (
        <div key={renderKey} className="p-2 sm:p-4">
            {/* Filter and Button Section */}
            <div className="bg-gray-800 text-white p-4 rounded shadow-md flex flex-col md:flex-row justify-between items-center mb-4 w-full">
                <div className="w-full md:w-auto mb-4 md:mb-0">
                    <select
                        value={sortType}
                        onChange={e => setSortType(e.target.value)}
                        className="w-full md:w-auto p-2 border bg-gray-700 text-white focus:outline-none focus:ring-1 focus:ring-indigo-500 rounded"
                    >
                        <option value="expire">Zeit bis Ablauf</option>
                        <option value="marketValue">Marktwert</option>
                        <option value="averagePoints">Durchschnittspunkte</option>
                        <option value="pointsPerEuro">Durchsch. Punkte/Euro</option>
                        <option value="valueChange">MW-Veränderung</option>
                        <option value="valueForMoney">Gesamtpunkte/Euro</option>
                    </select>
                </div>

                <div className="flex items-center">
                    <label htmlFor="showAllPlayers" className="flex items-center cursor-pointer mr-4">
                        <div className="relative">
                            <input
                                id="showAllPlayers"
                                type="checkbox"
                                className="hidden"
                                checked={showAllPlayers}
                                onChange={() => setShowAllPlayers(prev => !prev)}
                            />
                            <div className="toggle__line w-10 h-4 bg-gray-400 rounded-full shadow-inner"></div>
                            <div className="toggle__dot absolute w-6 h-6 bg-white rounded-full shadow inset-y-0 left-0"></div>
                        </div>
                        <div className="ml-3 text-white">Alle</div>
                    </label>

                    <FontAwesomeIcon 
                        className="text-white hover:text-orange-500 cursor-pointer text-xl" 
                        icon={faBinoculars} 
                        onClick={() => setSearchModalOpen(true)} 
                    />
                </div>
            </div>

            {loading ? (
                <div className="flex justify-center items-center h-64">
                    <ClipLoader size={50} color={"#123abc"} loading={loading} />
                </div>
            ) : (
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4">
                    {sortedAndFilteredPlayers.map(player => {
                      //  console.log(`Market values for ${player.firstName} ${player.lastName}:`, player.marketValues);
                        if (!player) return null;
                        
                        const playerData = calculatedPlayerData[player.id];
                       // console.log('Calculated player data:', player);
                        if (!playerData) return null; // Skip rendering if data is not yet calculated

                        const { trend, values } = player.calculateMarketValueTrend(7);
                      // console.log(`7-day trend for ${player.firstName} ${player.lastName}:`, trend);

                        const { canBuy, reason } = canBuyPlayer(player, playerData);

                        return (
                            <div key={player.id} className="bg-gray-800 p-6 rounded-xl shadow-2xl max-w-md transition-transform relative mr-2">
                                {/* Add this near the top of the player card */}
                                <button 
                                    onClick={() => handleNotificationRequest(player.id)}
                                    className="absolute top-4 right-4 text-white hover:text-[#f23d00] transition-colors duration-200"
                                >
                                    <FontAwesomeIcon 
                                        icon={notificationEnabled[player.id] ? faBell : faBellSlash} 
                                        className="text-1xl"
                                        style={{ color: notificationEnabled[player.id] ? '#f23d00' : 'white' }}
                                    />
                                </button>
                                {/* Player info */}
                                <div className="flex items-center mb-4">
                                <img 
    src={player.playerImage || `https://kickbase.b-cdn.net/pool/playersbig/${player.id}.png`}
    alt={`${player.firstName} ${player.lastName}`}
    className="w-16 h-16 rounded-full mr-4 object-cover object-center"
    onError={(e) => {
        e.target.onerror = null;
        e.target.src = 'https://kickbase.b-cdn.net/images/avatar.png'; // fallback image
    }}
/>
<div>
                                        <h4 className="text-lg font-bold text-white">{player.firstName} {player.lastName}</h4>
                                        <div className="flex items-center mt-1">
                                            <img 
                                                src={`https://kickbase.b-cdn.net/pool/teams/${player.teamId}.png`}
                                                alt={player.teamId}
                                                className="w-6 h-6 mr-2 rounded-full"
                                                onError={(e) => {
                                                    e.target.onerror = null;
                                                    e.target.src = `https://kickbase.b-cdn.net/pool/teamsdummies/${player.teamId}.png`;
                                                }}
                                            />

                                        </div>
                                        <span className="text-xs text-gray-400">{player.position}</span>

                                    </div>
                                </div>

                                {/* Market value - full width, clickable */}
                                <div className="mb-4">
                                    <button
                                        onClick={() => { setSelectedPlayer(player); setDataModalOpen(true); }}
                                        className="w-full bg-blue-500 text-white text-center py-2 px-4 rounded-lg hover:bg-blue-600 transition-colors duration-300"
                                    >
                                        {formatCurrency(player.marketValue)}
                                    </button>
                                </div>
                                
                                {/* Points cards - side by side */}
                                <div className="flex justify-between mb-4">
                                    <div className="bg-gray-700 text-white text-center py-2 px-4 rounded-lg w-[48%]">
                                        <span className="block text-sm text-gray-400">Ø Punkte</span>
                                        <span className="text-lg font-bold">{player.averagePoints?.toFixed(1) || 'N/A'}</span>
                                    </div>
                                    <div className="bg-gray-700 text-white text-center py-2 px-4 rounded-lg w-[48%]">
                                        <span className="block text-sm text-gray-400">Punkte</span>
                                        <span className="text-lg font-bold">{player.totalPoints || 'N/A'}</span>
                                    </div>
                                </div>

                                <div className="space-y-2 mt-2">
                                    {/* Next match info */}
                                    <div className="bg-gray-700 p-2 rounded-lg flex justify-between items-center">
                                        {player.nextOpponent ? (
                                            <div className="flex items-center">
                                                <span className="text-gray-300 text-sm mr-2">
                                                    {player.nextOpponent.homeOrAway} {player.nextOpponent.teamShortName}
                                                </span>
                                                <img
                                                    src={`https://kickbase.b-cdn.net/pool/teams/${player.nextOpponent.teamId}.png`}
                                                    alt={`${player.nextOpponent.teamName} Logo`}
                                                    className="w-6 h-6 object-cover rounded-full"
                                                    onError={(e) => {
                                                        e.target.onerror = null;
                                                        e.target.src = `https://kickbase.b-cdn.net/pool/teamsdummies/${player.nextOpponent.teamId}.png`;
                                                    }}
                                                />
                                            </div>
                                        ) : (
                                            <span className="text-gray-300 text-sm">-</span>
                                        )}
                                    </div>

                                    {/* Market value change */}
                                    <div className="bg-gray-700 p-2 rounded-lg flex justify-between items-center">
                                        <span className="text-gray-300 text-sm">Marktwertänderung:</span>
                                        <span className={`text-sm font-bold ${player.marketValueIncrease >= 0 ? 'text-green-500' : 'text-red-500'}`}>
                                            <FontAwesomeIcon 
                                                icon={player.marketValueIncrease >= 0 ? faArrowUp : faArrowDown} 
                                                className="mr-1" 
                                            />
                                            {formatCurrency(player.marketValueIncrease)}
                                        </span>
                                    </div>

                                    {/* Market value trend */}
                                    <div className="bg-gray-700 p-2 rounded-lg">
                                        <div className="flex items-center justify-between mb-1">
                                            <span className="text-gray-300 text-sm">MW-Trend (7 Tage):</span>
                                            <span className={`text-sm font-bold ${trend >= 0 ? 'text-green-500' : 'text-red-500'}`}>
                                                {formatCurrency(trend)} 
                                            </span>
                                        </div>
                                        {values.length > 1 ? (
                                            <MiniTrendGraph data={values} width={200} height={30} />
                                        ) : (
                                            <div className="text-gray-400 text-sm">Keine Verlaufsdaten verfügbar</div>
                                        )}
                                    </div>

                                    {/* Expiration countdown */}
                                    <div className="text-base font-semibold text-gray-300 py-2 px-3 rounded-lg bg-gray-700 text-center">
                                        {getCountdown(player.expire)}
                                    </div>

                                    {/* Recommendation section */}
                                    <div className={`bg-gray-700 p-2 rounded-lg ${showRecommendationDetails[player.id] ? 'pb-4' : ''}`}>
                                        <div className="flex justify-between items-center cursor-pointer mb-2" onClick={() => toggleRecommendationDetails(player.id)}>
                                            <span>Empfehlung:</span>
                                            <div className="flex items-center">
                                                <span className={`font-bold ${getFitColor(playerData.fitPercentage)} mr-2`}>
                                                    {isNaN(playerData.fitPercentage) ? '0' : Math.round(playerData.fitPercentage)}%
                                                </span>
                                                <FontAwesomeIcon
                                                    icon={showRecommendationDetails[player.id] ? faChevronUp : faChevronDown}
                                                    className="text-blue-500"
                                                />
                                            </div>
                                        </div>

                                        {/* Recommendation Details (collapsible) */}
                                        {showRecommendationDetails[player.id] && (
                                            <div className="mt-4 space-y-2 text-sm">
                                                {/* Form */}
                                                <div className="bg-gray-600 p-2 rounded-lg flex justify-between items-center">
                                                    <span>Form:</span>
                                                    <span>{playerData.form || 'Normale Form'}</span>
                                                </div>

                                                {/* Daily Value Increase */}
                                                <div className="bg-gray-600 p-2 rounded-lg flex justify-between items-center">
                                                    <span>Tägliche Wertsteigerung:</span>
                                                    <span>{formatCurrency(player.marketValueIncrease)}</span>
                                                </div>

                                                {/* Future Trend */}
                                                <div className="bg-gray-600 p-2 rounded-lg flex justify-between items-center">
                                                    <span>Zuknftiger Trend:</span>
                                                    <span>{playerData.futureValueIncrease ? formatCurrency(playerData.futureValueIncrease.estimatedDailyChange) : 'N/A'}</span>
                                                </div>

                                                {/* Position Comparison */}
                                                <div className="bg-gray-600 p-2 rounded-lg flex justify-between items-center">
                                                    <span>Vergleich auf der Pos.:</span>
                                                    <span>{playerData.comparisonResult ? `${playerData.comparisonResult.difference.toFixed(1)} Punkte/Spiel` : 'Keine Daten'}</span>
                                                </div>

                                                {/* Financial Fit */}
                                                <div className="bg-gray-600 p-2 rounded-lg flex justify-between items-center">
                                                    <span>Finanzielle Passung:</span>
                                                    <span>{!isNaN(playerData.financialPercentage) ? `${playerData.financialPercentage.toFixed(1)}% vom Budget` : 'Keine Daten'}</span>
                                                </div>

                                                {/* Free Positions */}
                                                <div className="bg-gray-600 p-2 rounded-lg flex justify-between items-center">
                                                    <span>Freie Plätze auf Pos.:</span>
                                                    <span>{`${playerData.positionCounts[player.position] || 0} / ${playerData.maxPlayers[player.position] || 'N/A'}`}</span>
                                                </div>

                                                {/* Players from Team */}
                                                <div className="bg-gray-600 p-2 rounded-lg flex justify-between items-center">
                                                    <span>Spieler aus dem Team:</span>
                                                    <span>{playerData.teamPlayersCount !== undefined ? `${playerData.teamPlayersCount} im Team` : 'Keine Daten'}</span>
                                                </div>

                                                {/* Estimated Sale Price */}
                                                {/* <div className="bg-gray-600 p-2 rounded-lg flex justify-between items-center">
                                                    <span>Geschätzter Verkaufspreis:</span>
                                                    <span>{playerData.estimatedSalePrice === '-' ? '-' : `${formatCurrency(playerData.estimatedSalePrice)} `}</span>
                                                </div>

                                               
                                                <div className="bg-gray-600 p-2 rounded-lg flex justify-between items-center">
                                                    <span>Spiele absolviert:</span>
                                                    <span>{`${playerData.gamesPlayed} / ${playerData.totalGames}`}</span>
                                                </div> */
                                                }
                                            </div>
                                        )}
                                    </div>

                                    {/* Cannot Buy Message */}
                                    {!canBuy && (
                                        <div className="bg-red-600 p-2 rounded-lg text-white text-center mt-2 text-sm">
                                            Kann nicht gekauft werden: {reason}
                                        </div>
                                    )}
                                </div>

                                {/* Add VoteButtons component */}
                                <div className="mt-4">
                                     <div className="bg-gray-700 p-2 rounded-lg flex items-center justify-between">
                                         <span className="text-sm font-bold text-white mr-2">
                                             Deine Einschätzung:
                                         </span>
                                         <VoteButtons playerId={player.id} userId={userId} />
                                     </div>
                                 </div>
                            </div>
                        );
                    })}
                </div>
            )}

            {/* Modals for Player Detail and Search */}
            {dataModalOpen && selectedPlayer && (
                <Modal
                    isOpen={dataModalOpen}
                    onRequestClose={() => setDataModalOpen(false)}
                    overlayClassName="ReactModal__Overlay"
                    className="ReactModal__Content justify-center"
                >
                    <button
                        className="z-50 close-button text-4xl text-gray-600 hover:text-gray-800 transition duration-150 ease-in-out"
                        onClick={() => setDataModalOpen(false)}
                    >
                        &times;
                    </button>
                    <PlayerDetailModal player={selectedPlayer} onClose={() => setDataModalOpen(false)} />
                </Modal>
            )}
            {searchModalOpen && (
                <Modal
                    isOpen={searchModalOpen}
                    onRequestClose={() => setSearchModalOpen(false)}
                    overlayClassName="fixed inset-0 flex items-center justify-center z-50"
                    className="bg-transparent"
                >
                    <button
                        className="absolute top-2 right-2 text-4xl text-gray-600 hover:text-gray-800 transition duration-150 ease-in-out"
                        onClick={() => setSearchModalOpen(false)}
                    >
                        &times;
                    </button>
                    <PlayerSearch onClose={() => setSearchModalOpen(false)} />
                </Modal>
            )}

            {showNotificationDialog && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white p-6 rounded-lg shadow-xl max-w-sm">
                        <h2 className="text-xl font-bold mb-4">Benachrichtigungen aktivieren</h2>
                        <p className="mb-4">
                            Wir möchten dir Benachrichtigungen senden, um dich 10 Minuten vor dem Auktionsende eines Spielers zu informieren. 
                            Dies hilft dir, keine wichtigen Auktionen zu verpassen.
                        </p>
                        <div className="flex justify-end space-x-4">
                            <button 
                                onClick={() => setShowNotificationDialog(false)} 
                                className="px-4 py-2 bg-gray-300 rounded hover:bg-gray-400"
                            >
                                Abbrechen
                            </button>
                            <button 
                                onClick={handleNotificationDialogConfirm} 
                                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                            >
                                Erlauben
                            </button>
                        </div>
                    </div>
                </div>
            )}

        </div >
    );
}
);

export default MarketOverview;