import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { getAnalytics } from "firebase/analytics";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getFirestore } from 'firebase/firestore';  // Add this import

const firebaseConfig = {
  apiKey: "AIzaSyCSKl6kC3tRFdHJJbOrAohZPVv7bL4bDa8",
  authDomain: "kickbase-analytic.firebaseapp.com",
  databaseURL: "https://kickbase-analytic-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "kickbase-analytic",
  storageBucket: "kickbase-analytic.appspot.com",
  messagingSenderId: "711239456594",
  appId: "1:711239456594:web:44ceca1905dec0c24c8c8b",
  measurementId: "G-RX05XRD78G"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const messaging = getMessaging(app);
const functions = getFunctions(app);
const firestore = getFirestore(app);  // Initialize Firestore

export const requestForToken = async () => {
  try {
    const currentToken = await getToken(messaging, { 
      vapidKey: 'BFHQmdnxCHyJ0OK5bKIOaaWGPkxNsTH9ZcNLGOb3obgS0njFG-jHXobH9qJFLUsLVfJ6HbfQElIAE6S7rLeloLs' 
    });
    if (currentToken) {
      console.log('current token for client: ', currentToken);
      return currentToken;
    } else {
      console.log('No registration token available. Request permission to generate one.');
    }
  } catch (err) {
    console.log('An error occurred while retrieving token. ', err);
  }
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

export const scheduleNotification = httpsCallable(functions, 'scheduleNotification');

// New function to subscribe to player notifications
export const subscribeToPlayerNotifications = httpsCallable(functions, 'subscribeToPlayerNotifications');

// Add this line near the other httpsCallable functions
export const unsubscribeFromPlayerNotifications = httpsCallable(functions, 'unsubscribeFromPlayerNotifications');

export { app, analytics, messaging, firestore };  // Export firestore