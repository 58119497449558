import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp, faThumbsDown } from '@fortawesome/free-solid-svg-icons';
import { doc, getDoc, setDoc, Timestamp } from 'firebase/firestore';
import { firestore } from '../firebase';  // This line is correct now

const VoteButtons = ({ playerId, userId }) => {
  const [userVote, setUserVote] = useState(null);
  const [votes, setVotes] = useState({ upvotes: 0, downvotes: 0 });

  useEffect(() => {
    const fetchVotes = async () => {
      if (!playerId) return;

      const voteRef = doc(firestore, 'playerVotes', playerId);
      const voteDoc = await getDoc(voteRef);
      
      if (voteDoc.exists()) {
        const voteData = voteDoc.data();
        console.log('Vote data:', voteData);

        const validVotes = { upvotes: 0, downvotes: 0 };

        if (voteData.upvotes) {
          validVotes.upvotes = Array.isArray(voteData.upvotes) ? voteData.upvotes.length : voteData.upvotes;
        }

        if (voteData.downvotes) {
          validVotes.downvotes = Array.isArray(voteData.downvotes) ? voteData.downvotes.length : voteData.downvotes;
        }

        setVotes(validVotes);
      }

      if (userId) {
        const userVoteRef = doc(firestore, 'userVotes', `${userId}_${playerId}`);
        const userVoteDoc = await getDoc(userVoteRef);
        if (userVoteDoc.exists()) {
          const userVoteData = userVoteDoc.data();
          setUserVote(userVoteData.vote);
        }
      }
    };

    fetchVotes();
  }, [playerId, userId]);

  const handleVote = async (voteType) => {
    if (!userId || !playerId) {
      console.error('Cannot vote: userId or playerId is missing');
      return;
    }

    const voteRef = doc(firestore, 'playerVotes', playerId);
    const userVoteRef = doc(firestore, 'userVotes', `${userId}_${playerId}`);

    try {
      const timestamp = Timestamp.now();
      if (userVote === voteType) {
        // Remove vote
        await setDoc(voteRef, {
          [`${voteType}s`]: votes[`${voteType}s`] - 1
        }, { merge: true });
        await setDoc(userVoteRef, { vote: null, timestamp: null }, { merge: true });
        setUserVote(null);
        setVotes(prev => ({ ...prev, [`${voteType}s`]: prev[`${voteType}s`] - 1 }));
      } else {
        // Add or change vote
        const updates = {};
        if (userVote) {
          updates[`${userVote}s`] = votes[`${userVote}s`] - 1;
        }
        updates[`${voteType}s`] = (votes[`${voteType}s`] || 0) + 1;
        
        await setDoc(voteRef, updates, { merge: true });
        await setDoc(userVoteRef, { vote: voteType, timestamp }, { merge: true });
        setUserVote(voteType);
        setVotes(prev => ({
          ...prev,
          ...updates
        }));
      }
    } catch (error) {
      console.error("Error handling vote:", error);
    }
  };

  return (
    <div className="flex items-center space-x-4">
      <button
        onClick={() => handleVote('upvote')}
        className={`flex items-center space-x-1 px-2 py-1 rounded ${
          userVote === 'upvote' ? 'bg-green-500 text-white' : 'bg-gray-200 text-gray-700'
        } transition-all duration-200 hover:bg-green-400`}
        disabled={!userId}
      >
        <FontAwesomeIcon icon={faThumbsUp} />
        <span>{votes.upvotes || 0}</span>
      </button>
      <button
        onClick={() => handleVote('downvote')}
        className={`flex items-center space-x-1 px-2 py-1 rounded ${
          userVote === 'downvote' ? 'bg-red-500 text-white' : 'bg-gray-200 text-gray-700'
        } transition-all duration-200 hover:bg-red-400`}
        disabled={!userId}
      >
        <FontAwesomeIcon icon={faThumbsDown} />
        <span>{votes.downvotes || 0}</span>
      </button>
    </div>
  );
};

export default VoteButtons;