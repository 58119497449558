import React, { useState, useEffect, createContext, useContext } from 'react';
import { motion } from 'framer-motion'; // Make sure to import motion
import { playerStoreInstance } from './stores/PlayerStore';
import { login } from './api';
import { useCookieContext } from './CookieContext';
import { Link, useNavigate } from 'react-router-dom'; // Make sure you have this import

import background from './background.png';
import logo from './analyticlogo.png';

const Login = (props) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [user, setUser] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('token');
        const savedUser = localStorage.getItem('user');

        if (token && savedUser) {
            setUser(JSON.parse(savedUser));
            setSuccessMessage('Willkommen ' + JSON.parse(savedUser).name + '!');
        }
    }, []);

    const { cookiesAccepted } = useCookieContext();


    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        setUser(null);
    };

    const handleUsernameChange = (e) => {
        setUsername(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await login(username, password);

            const token = response.token;
            const currentTime = new Date().getTime();
            localStorage.setItem('token', token);
            localStorage.setItem('token_timestamp', currentTime);
            localStorage.setItem('user', JSON.stringify(response.user));

            if (response.user && response.user.id) {
                playerStoreInstance.fetchUserInfo(response.user.id);
            }

            setError('');
            setUser(response.user);
            setSuccessMessage('Login successful! Welcome ' + response.user.name + '!');

            // Instead of reloading, navigate to the main app
            navigate('/dashboard');

            // Check if checkToken is available before calling it
            if (props.checkToken && typeof props.checkToken === 'function') {
                props.checkToken();
            }

            playerStoreInstance.initialize(); // Initialize the store after successful login
            
            window.location.reload();

        } catch (error) {
            console.error('Login error:', error);

            // Check if error has a response and a status code
            if (error.response && error.response.status === 401) {
                setError('Falsche Anmeldedaten');
                alert('Falsche Anmeldedaten');
            } 

            setUser(null);
            setSuccessMessage('');
        }
    };

    return (
        <div className="min-h-screen bg-gray-900 flex flex-col justify-center items-center p-4">
            
            {/* New notification message */}
            <motion.div 
                className="w-full max-w-md bg-blue-600 rounded-lg shadow-md p-4 mb-6"
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
            >
                <p className="text-sm text-white text-center">
                    <span className="font-bold">Neu (Beta): Funktioniert aktuell noch nicht ganz korrekt. Benachrichtigungen für Spielerauktionen!</span><br />
                    <br />
                    Erhalte eine Erinnerung 10 Minuten vor Auktionsende.<br />
                    Aktiviere sie auf der Marktübersicht.
                </p>
            </motion.div>

            {/* Sorry message card */}
         

            {/* Login form card */}
            <div className="w-full max-w-md bg-gray-800 rounded-lg shadow-md p-6 mb-6">
                <h2 className="text-2xl font-bold text-white mb-4 text-center">Dein ultimativer Kickbase Begleiter</h2>
                
                <div className="mb-4 text-center">
                    <p className="text-gray-400">
                        Sicherer Login über die Kickbase API. Der Anmeldetoken wird lokal auf deinem Gerät gespeichert.
                    </p>
                    {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
                </div>
                <form onSubmit={handleSubmit} className="space-y-6">
                    <div>
                        <input
                            type="text"
                            placeholder="Kickbase Email"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            className="w-full px-4 py-2 rounded-lg bg-gray-700 text-white placeholder-gray-500 border border-gray-600 focus:border-orange-500 focus:outline-none"
                        />
                    </div>
                    <div>
                        <input
                            type="password"
                            placeholder="Kickbase Passwort"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="w-full px-4 py-2 rounded-lg bg-gray-700 text-white placeholder-gray-500 border border-gray-600 focus:border-orange-500 focus:outline-none"
                        />
                    </div>
                    <div className="text-center">
                        <button
                            type="submit"
                            className={`w-full px-4 py-2 rounded-lg ${'bg-orange-600 hover:bg-orange-700'} text-white font-semibold focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-opacity-50`}
                        >
                            {'Einloggen'}
                        </button>
                    </div>
                </form>
            
                <p className="text-gray-400 text-xs text-center mt-6">
                    Diese Seite steht in keinerlei Beziehung zu Kickbase.
                </p>
            </div>
            
            {/* Animated Kickly Cup call-to-action */}
            <motion.div 
                className="w-full max-w-md bg-[#1e2124] rounded-lg shadow-md p-6 text-center"
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ 
                    opacity: 1, 
                    scale: [1, 1.02, 1],
                    transition: {
                        scale: {
                            repeat: Infinity,
                            duration: 2,
                            ease: "easeInOut"
                        }
                    }
                }}
                whileHover={{ scale: 1.05, transition: { duration: 0.2 } }}
            >
                <h3 className="text-xl font-bold text-[#9fef00] mb-2">
                    Kickly Cup
                </h3>
                <p className="text-white mb-4">Bereit für dein nächstes Pokal-Abenteuer?</p>
                <motion.a 
                    href="https://kickly-cup.de" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="inline-block bg-[#9fef00] hover:bg-[#8cde00] text-[#1e2124] font-bold py-2 px-4 rounded transition duration-300"
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.95 }}
                >
                    Jetzt mitmachen
                </motion.a>
            </motion.div>
        </div>
    );
};

export const AuthContext = createContext();

export default Login;